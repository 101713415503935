// extracted by mini-css-extract-plugin
export var BackgroundImage = "index-module--BackgroundImage--42054";
export var ButtonGroupDesktop = "index-module--ButtonGroupDesktop--6aca4";
export var ButtonGroupRow = "index-module--ButtonGroupRow--4969f";
export var ButtonGroupTile = "index-module--ButtonGroupTile--6d84f";
export var ButtonWithIcon = "index-module--ButtonWithIcon--69321";
export var Content = "index-module--Content--c49dd";
export var GiftsNotificationDesktop = "index-module--GiftsNotificationDesktop--5ee5d";
export var GiftsNotificationMobile = "index-module--GiftsNotificationMobile--2434c";
export var HideOnDesktop = "index-module--HideOnDesktop--818fa";
export var HideOnMobile = "index-module--HideOnMobile--f18f8";
export var HomeTitle = "index-module--HomeTitle--d54cf";
export var MobileBottomButtons = "index-module--MobileBottomButtons--6024e";
export var RTL = "index-module--RTL--1731c";
export var RoundedMobile = "index-module--RoundedMobile--a75b8";
export var ScanAlternative = "index-module--ScanAlternative--86129";
export var TopSideButtons = "index-module--TopSideButtons--965e5";
export var TopSideMobileButtons = "index-module--TopSideMobileButtons--feee8";
export var fadeAndGrowIn = "index-module--fadeAndGrowIn--115d8";