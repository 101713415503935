import React, { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { filter, get, includes, isEmpty, min, uniq } from "lodash";
import {
  Avatar,
  Box,
  Card,
  IconButton,
  Paper,
  Typography,
} from "@mui/material";
import { Add } from "@mui/icons-material";
import Slider from "react-slick";
import { makeStyles } from "../../AppContainer/mui-theme";
import "../slick-theme.css";
import Image from "../../image";
import { mediaTypes } from "../../../utils/media";
import NoImageRenderer from "../../no-image-renderer";
import { navigateTo } from "../../../utils/location";
import {
  setFeaturedProductToByInOrder,
  startNewOrder,
} from "../../../store/order/actions";
import { openNewOrPrevOrderModal } from "../../../store/app/actions";
import { getFeaturedItems } from "../../../store/selectors";
import AllergensDecorator from "../../allergens-decorator";

const FeaturedWidget = (props) => {
  const { classes } = useStyles();
  const dispatch = useDispatch();

  const {
    appStyles,
    widget,
    pageContext,
    pageContext: {
      business: { allergens },
    },
  } = props;
  const featuredItems = useSelector((state) => getFeaturedItems(state, props));
  const order = useSelector((state) => state.order);
  const isMobile = useSelector((state) => state.app.isMobile);

  const sliderRef = useRef(null);
  const dotsRef = useRef(null);

  const handleAddFeaturedItemToOrder = (item) => {
    const { orderItems } = order;

    const skipServingOptionsPage = get(
      appStyles,
      "skipServingOptionsPage",
      false
    );

    const isDuringOrder = !isEmpty(orderItems);

    const navigateToNextPage = () => {
      navigateTo(
        skipServingOptionsPage || pageContext.servingOptions.length < 2
          ? "/find-location"
          : "/serving-options"
      );
    };

    if (isDuringOrder) {
      dispatch(openNewOrPrevOrderModal());
    } else {
      dispatch(startNewOrder());
      navigateToNextPage();
    }
    dispatch(setFeaturedProductToByInOrder(item.id));
  };

  const settings = {
    infinite: false,
    speed: 1000,
    slidesToShow: 1.2, // to show a part of next slide
    slidesToScroll: 1,
    arrows: false,
    dots: false,
    focusOnSelect: true,
  };

  const renderCarusleMenuItem = (item, index) => (
    <div
      key={index}
      style={{
        height: "auto",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      {get(item, "imageKey") ? (
        <Image
          mediaKey={item.imageKey}
          mediaType={mediaTypes.menuItem}
          imagePreview={item.imagePreview}
          isWidget
        />
      ) : (
        <NoImageRenderer appStyles={props.appStyles} isWidget />
      )}
    </div>
  );

  const allergensForItem = (item) =>
    filter(allergens, (allergen) => includes(item.allergens, allergen.id));

  const sliderContent = filter(featuredItems, (item) => item).map(
    (item, index) => (
      <div key={index}>
        <Paper
          elevation={3}
          sx={{
            margin: "10px",
            borderRadius: "10px",
          }}
        >
          {renderCarusleMenuItem(item)}
          <Box sx={{ p: "16px" }}>
            <Typography
              gutterBottom
              variant="h5"
              fontWeight="bold"
              component="div"
              sx={{
                display: "block",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                fontSize: "large"
              }}
            >
              {item.name}
            </Typography>
            <Typography
              variant="body1"
              component={"span"}
              gutterBottom
              sx={{
                display: "block",
                overflow: "hidden",
                textOverflow: "ellipsis",
                maxHeight: "2.4em", // Sets max height for three lines
                lineHeight: "1.2em", // Defines line height
                height: "2.4em", // Ensures a static height
                color: appStyles.textColor,
                fontSize: "small",
              }}
            >
              {item.description}
            </Typography>
            <Box
              sx={{
                display: "flex",
                justifyContent: !isEmpty(allergensForItem(item))
                  ? "space-between"
                  : "flex-end",
                alignItems: "center",
              }}
            >
              <AllergensDecorator
                allergens={allergensForItem(item)}
                appStyles={appStyles}
              />
              <IconButton
                sx={{ padding: 0, width: 30, height: 30 }}
                onClick={() => handleAddFeaturedItemToOrder(item)}
              >
                <Avatar
                  sx={{
                    background: appStyles.actionColor,
                    width: 30,
                    height: 30,
                  }}
                >
                  <Add sx={{ width: 20, height: 20 }} />
                </Avatar>
              </IconButton>
            </Box>
          </Box>
        </Paper>
      </div>
    )
  );

  const DesktopWrapper = ({ children }) =>
    !isMobile ? (
      <Card style={{ borderRadius: appStyles.cardBorderRadius }}>
        {children}
      </Card>
    ) : (
      children
    );

  return (
    featuredItems &&
    !isEmpty(featuredItems) && (
      <DesktopWrapper>
        <Box>
          {!isEmpty(widget.title) && (
            <Typography gutterBottom variant="h5" sx={{ fontWeight: "bolder", fontSize: "large" }}>
              {widget.title}
            </Typography>
          )}
          {!isEmpty(widget.subtitle) && (
            <Typography gutterBottom variant="subtitle1" component="h4">
              {widget.subtitle}
            </Typography>
          )}
          {!isEmpty(widget.content) && (
            <Typography variant="body1" component="p">
              {widget.content}
            </Typography>
          )}
          <Slider
            ref={sliderRef}
            {...settings}
            className={classes.featuredSlider}
          >
            {sliderContent}
          </Slider>
        </Box>
      </DesktopWrapper>
    )
  );
};

const desktopCardPromotionWidth = 376;

const useStyles = makeStyles()((theme) => ({
  featuredSlider: {
    height: `calc(100vw - ${theme.spacing(8)})`,
    width: `calc(100vw - ${theme.spacing(4)})`,
    [theme.breakpoints.up("sm")]: {
      height: "320px",
      width: `calc(${desktopCardPromotionWidth}px - ${theme.spacing(4)})`,
    },
  },
}));

export default FeaturedWidget;
