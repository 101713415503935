import classnames from 'classnames';
import { withPrefix } from 'gatsby';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
import React from 'react';
import * as styles from './index.module.scss';

export default ({ allergens, appStyles, wrapperClassNames }) =>
  !isEmpty(allergens) && (
    <div className={wrapperClassNames}>
      {map(allergens, (allergen, index) => (
        <img
          key={`allergen-${allergen.path}-${index}`}
          src={withPrefix(allergen.path)}
          className={classnames(styles.AllergenIcon, appStyles.rtl && styles.RTL)}
        />
      ))}
    </div>
  );
