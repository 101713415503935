// extracted by mini-css-extract-plugin
export var Center = "index-module--Center--53813";
export var HideOnDesktop = "index-module--HideOnDesktop--fd88d";
export var HideOnMobile = "index-module--HideOnMobile--dbc29";
export var HomePoints = "index-module--HomePoints--75dcd";
export var HomeTitle = "index-module--HomeTitle--71da3";
export var HomeTitleDesktop = "index-module--HomeTitleDesktop--17a45";
export var LoyaltyContainer = "index-module--LoyaltyContainer--37814";
export var TitlePadding = "index-module--TitlePadding--9873f";
export var divider = "index-module--divider--20f24";
export var label = "index-module--label--3aabe";
export var line = "index-module--line--18d9c";
export var visitAndPoints = "index-module--visitAndPoints--a6330";