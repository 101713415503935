import { get, filter, first, sortBy } from "lodash";
import { appfrontCdnRoot, promotionMediaType } from "./media";

// deprecated
export default function getPromotionImage(promotion) {
  const firstMediaItem =
    first(sortBy(get(promotion, "media"), "mediaType")) ||
    get(promotion, "image");

  const imageKey = get(firstMediaItem, "key");

  return imageKey ? `${appfrontCdnRoot}/${imageKey}` : "video";
}
