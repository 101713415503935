import React from "react";
import classnames from "classnames";
import {getPointsBalanceFromUser} from "../../../logic/user/getters/getPointsBalanceFromUser";
import { filter, uniq, sortBy, uniqBy, isNull} from 'lodash';
import * as styles from "./index.module.scss";
const MAX_MILESTONES = 6;

function filterNextMilestones(pointBankCoupons, rewardPointsBalance) {
  return filter((pointBankCoupons||[]), ((coupon) => coupon.price - rewardPointsBalance > 0))
}

function filterAndSortMilestones(pointBankCoupons, rewardPointsBalance) {
  if (!pointBankCoupons)  {
    return [];
  }

  const uniq = uniqBy(pointBankCoupons, 'price')
  const sorted = sortBy(uniq, c => c.price)
  const nextMilestones = filterNextMilestones(sorted, rewardPointsBalance);
  const sortedWithoutNext = filter(sorted, ((coupon) => coupon.price - rewardPointsBalance <= 0));
  let output = []
  let i = 0;
  while (++i < MAX_MILESTONES) {
    if (nextMilestones[0]) {
      output.push(nextMilestones.shift());
      continue;
    }
    if (sortedWithoutNext[0]) {
      output.push(sortedWithoutNext.pop());
    }
  }

  return sortBy(output, 'price');
}

function limitArrayTo6(array) {
  if (array.length > 6) {
    const firstFive = array.slice(0, 5);
    const lastElement = array[array.length - 1];
    return [...firstFive, lastElement];
  }
  return array;
}

const findIndexForCurrentBalance = (rewardPointsBalance, couponMilestonesPrices) => {
  for (let i = 0; i< couponMilestonesPrices.length; i++) {
    if (rewardPointsBalance < couponMilestonesPrices[i]) {
      return i - 1;
    }
  }

  return 0;
}


const calculateFillWidthBetweenMilestones = (rewardPointsBalance, couponMilestonesPrices) => {
  const indexForCurrentBalance = findIndexForCurrentBalance(rewardPointsBalance, couponMilestonesPrices);
  const currentMilestonePrice = couponMilestonesPrices[indexForCurrentBalance] || 0;

  const nextIndex = indexForCurrentBalance + 1;
  const nextMilestonePrice = couponMilestonesPrices[nextIndex];
  const milestonesAmount = couponMilestonesPrices.length;

  const addition = (((rewardPointsBalance - currentMilestonePrice) / ((nextMilestonePrice-currentMilestonePrice))))
  const additionPx = addition*(100/milestonesAmount);
  const fill = ((indexForCurrentBalance+1) / milestonesAmount);
  return (fill) * 100 + additionPx;
}


function formatPrice(price) {
  if (!price) {
    return '0';
  }
  if (price < 1000) {
    return price.toString(); // Return the price as is if less than 1000
  } else if (price < 1000000) {
    return (price / 1000).toFixed(1) + 'K'; // Convert to 'K' for thousands
  } else if (price < 1000000000) {
    return (price / 1000000).toFixed(1) + 'M'; // Convert to 'M' for millions
  } else {
    return (price / 1000000000).toFixed(1) + 'B'; // Convert to 'B' for billions
  }
}

function Label({ price, maxPrice, isLast, index, totalIndexes}) {
  const left = ((index / totalIndexes * 100));
  const leftStr = `calc(${left}%)`
  const formattedPrice = formatPrice(price);
  return (
    <>
      <div className={classnames(styles.label )} style={{ left: leftStr}}>
        <div className={styles.price}>
          {formattedPrice}
        </div>
        { !isLast && (
          <div className={styles.marker} />
        )}
      </div>
    </>
  )
}

export default ({
  user,
  pointBankCoupons,
  appStyles
}) => {
  const rewardPointsBalance = getPointsBalanceFromUser(user) || 0;
  const sortedCouponMilestones = filterAndSortMilestones(pointBankCoupons, rewardPointsBalance);
  const couponMilestonesPrices = uniq(sortedCouponMilestones.map(c => c.price));
  const nextCouponMilestones = filterNextMilestones(sortedCouponMilestones, rewardPointsBalance);
  const nextCouponMilestone = nextCouponMilestones[0];
  const lastCouponMilestone = sortedCouponMilestones[sortedCouponMilestones.length - 1];
  const pointsToNextMilestone = nextCouponMilestone ? nextCouponMilestone.price - rewardPointsBalance : undefined;
  if (!nextCouponMilestone && !lastCouponMilestone) {
    return null;
  }

  const fillWidth = calculateFillWidthBetweenMilestones(rewardPointsBalance, couponMilestonesPrices)
  return (
    <div className={styles.loyaltyTracker}>
      { typeof pointsToNextMilestone === 'number' && (
        <div className={styles.milestoneInfo}>
          {pointsToNextMilestone} points until your next reward
        </div>
      )}
      <div className={styles.progressBarContainer}>
        <div className={styles.progressBar}>
          <div className={styles.barBackground}>
            <div className={styles.barFill} style={{width: `${fillWidth}%`, background: appStyles.pointsBankProgressColor}} />
          </div>
          <div className={styles.startLabel}>0</div>
          {
            couponMilestonesPrices.map((price, index) => (
              <Label
                price={price}
                maxPrice={lastCouponMilestone.price}
                isLast={index === couponMilestonesPrices.length-1}
                index={index+1}
                totalIndexes={couponMilestonesPrices.length}
              />
            ))
          }
          {/*<div className={styles.endLabel}>{lastCouponMilestone.price}</div>*/}
        </div>
      </div>
    </div>
  );
};
