import classnames from "classnames";
import _ from "lodash";
import React from "react";
import Button from "../Button";
import ButtonGroup from "../ButtonGroup";
import GiftNotificationBadge from "../GiftNotificationBadge";
import CreditCardEmptyIcon from "../icons/CreditCardEmpty.svg";
import GiftIcon from "../icons/Gift.svg";
import IceCreamIcon from "../icons/ice-cream.svg";
import LearnMoreIcon from "../icons/LearnMore.svg";
import NewOrderIcon from "../icons/NewOrder.svg";
import NewOrderPizzaIcon from "../icons/NewOrderPizza.svg";
import PizzaReorderIcon from "../icons/PizzaReorder.svg";
import ReorderIcon from "../icons/Reorder.svg";
import ScanIcon from "../icons/Scan.svg";
import TableIcon from "../icons/Table.svg";
import {LOGIN_TYPES} from "../LoginView";
import * as styles from "./index.module.scss";
import {isTemplateEnabled} from "../../logic/templates/isTemplateEnabled";
import {HOMEPAGE_TEMPLATE_KEY} from "../../utils/constants";


function RewardsButton({
  hideIcons,
  appStyles,
  T,
  onClick,
  tilesMode,
  amountOfGiftsUnseen,
  isMobile,
  ...otherProps
}) {
  return (
    <Button
      onClick={onClick}
      appStyles={appStyles}
      {...otherProps}
    >
      <span style={tilesMode ? {textAlign: "center"} : {}}>
        {T("My Rewards")}
      </span>
      <div
        style={{
          position: "relative",
        }}
      >
        {(!hideIcons && !appStyles.noIcons) && <GiftIcon/>}
        <GiftNotificationBadge
          appStyles={appStyles}
          amountOfGiftsUnseen={amountOfGiftsUnseen}
          classNames={classnames(
            isMobile
              ? styles.GiftsNotificationMobile
              : styles.GiftsNotificationDesktop,
            appStyles.rtl && styles.RTL,
          )}
          withBounce
        />
      </div>
    </Button>
  )
}

function RewardsAlternativeButton({hideIcons, appStyles, T, to, ...otherProps}) {
  return (
    <Button
      isExternalLink
      to={to}
      appStyles={appStyles}
      {...otherProps}
    >
      <span>{T(appStyles.rewardsAlternative.label)}</span>
      {(!hideIcons && !appStyles.noIcons) && (
        <TableIcon style={{width: 37, height: "auto"}}/>
      )}
    </Button>
  )
}

function RechargeButton({
  hideIcons,
  appStyles,
  T,
  navigateToTargetOrLoginMobile,
  iconStyles = {width: 37},
  ...otherProps
}) {
  return (
    <Button
      onClick={navigateToTargetOrLoginMobile({
        type: LOGIN_TYPES.PREPAID,
        target: "/prepaid",
      })}
      appStyles={appStyles}
      {...otherProps}
    >
      <span>{T("Recharge Card")} </span>
      {(!hideIcons && !appStyles.noIcons) && (
        <CreditCardEmptyIcon style={iconStyles}/>
      )}
    </Button>
  )
}

function ScanAlternativeButton({hideIcons, appStyles, T, to, ...otherProps}) {
  return (
    <Button
      isExternalLink
      to={appStyles.scanAlternative.href}
      appStyles={appStyles}
      {...otherProps}
    >
              <span style={{textAlign: "center"}}>
                {T(appStyles.scanAlternative.label)}
              </span>{" "}
      {!hideIcons && (
        appStyles.scanAlternative.useLearnMoreIcon ? (
          <LearnMoreIcon style={{width: "auto", maxHeight: 66}}/>
        ) : (
          <TableIcon style={{width: 37}}/>
        )
      )}
    </Button>
  )
}

function ScanButton({hideIcons, appStyles, T, navigateToTargetOrLoginMobile, ...otherProps}) {
  return (
    <Button
      onClick={navigateToTargetOrLoginMobile({
        type: LOGIN_TYPES.SCAN,
        target: "/scan",
      })}
      appStyles={appStyles}
      {...otherProps}
    >
      <span>{T("Scan")}</span> {(!hideIcons && !appStyles.noIcons) && <ScanIcon/>}
    </Button>
  )
}

function OrderButton({hideIcons, appStyles, T, StartNewOrderIcon, handleStartNewOrderClicked, ...otherProps}) {
  return (
    <Button onClick={handleStartNewOrderClicked} appStyles={appStyles} {...otherProps}>
            <span
              style={
                appStyles.noIcons || appStyles.homepageTileButtons
                  ? {textAlign: "center"}
                  : {textAlign: appStyles.rtl ? "right" : "left"}
              }
            >
              {T("Start New Order")}
            </span>
      {(!hideIcons && !appStyles.noIcons) && (
        <span style={{flexShrink: 0}}>
                <StartNewOrderIcon/>
              </span>
      )}
    </Button>
  )
}

function ReorderButton({hideIcons, appStyles, T, navigateToTargetOrLoginDesktop, ...otherProps}) {
  return (
    <Button
      onClick={navigateToTargetOrLoginDesktop({
        type: LOGIN_TYPES.REORDER,
        target: "/reorder",
      })}
      appStyles={appStyles}
      {...otherProps}
    >
      <span>{T("Reorder")}</span>
      {(!hideIcons && !appStyles.noIcons) &&
        (appStyles.isPizza ? <PizzaReorderIcon/> : <ReorderIcon/>)}
    </Button>
  )
}

function DesktopHomeButtons({
  appStyles,
  handleStartNewOrderClicked,
  T,
  StartNewOrderIcon,
  navigateToTargetOrLoginDesktop,
  amountOfGiftsUnseen,
  disableRewards
}) {
  const {
    HomeButtonsDesktop = {},
    roundedHomeButtons,
  } = appStyles;

  const disableReorder = _.includes(appStyles.disabledFeatures, "reorder");
  const disableOrder = _.includes(appStyles.disabledFeatures, "order");

  return (
    <ButtonGroup
      appStyles={appStyles}
      round={roundedHomeButtons}
      classNames={classnames(
        styles.HideOnMobile,
        appStyles.homepageTileButtons
          ? styles.ButtonGroupTile
          : styles.ButtonGroupDesktop,
      )}
      style={HomeButtonsDesktop}
    >
      {!disableOrder && (
        <OrderButton
          appStyles={appStyles}
          T={T}
          StartNewOrderIcon={StartNewOrderIcon}
          handleStartNewOrderClicked={handleStartNewOrderClicked}
        />
      )}
      {!disableReorder && (
        <ReorderButton
          appStyles={appStyles}
          T={T}
          navigateToTargetOrLoginDesktop={navigateToTargetOrLoginDesktop}
        />
      )}

      {!disableRewards &&
        (appStyles.rewardsAlternative ? (
          <RewardsAlternativeButton
            appStyles={appStyles}
            T={T}
            to={appStyles.rewardsAlternative.href}
          />
        ) : (
          <RewardsButton
            onClick={navigateToTargetOrLoginDesktop({
              type: LOGIN_TYPES.GIFTS,
              target: "/gifts",
            })}
            appStyles={appStyles}
            T={T}
            isMobile={false}
            amountOfGiftsUnseen={amountOfGiftsUnseen}
          />
        ))}
    </ButtonGroup>
  )
}

function MinimalTemplateButtons({
  appStyles,
  T,
  StartNewOrderIcon,
  handleStartNewOrderClicked,
  navigateToTargetOrLoginDesktop,
  amountOfGiftsUnseen,
  isMobile
}) {
  const buttons = [];
  const disableReorder = _.includes(appStyles.disabledFeatures, "reorder");
  const disableOrder = _.includes(appStyles.disabledFeatures, "order");
  const {
    HomeButtonsMobile = {},
    roundedHomeButtons,
  } = appStyles;

  if (!disableOrder) {
    buttons.push(<OrderButton
      hideIcons
      appStyles={appStyles}
      T={T}
      StartNewOrderIcon={StartNewOrderIcon}
      handleStartNewOrderClicked={handleStartNewOrderClicked}
    />);
  }
  if (buttons.length < 2 && !disableReorder) {
    buttons.push((
      <ReorderButton
        hideIcons
        appStyles={appStyles}
        T={T}
        navigateToTargetOrLoginDesktop={navigateToTargetOrLoginDesktop}
      />
    ));
  }
   if (buttons.length < 2 && !disableRewards) {
     buttons.push((
       <RewardsButton
         appStyles={appStyles}
         T={T}
         onClick={
           navigateToTargetOrLoginMobile({
             type: LOGIN_TYPES.GIFTS,
             target: "/gifts",
           })
         }
         amountOfGiftsUnseen={amountOfGiftsUnseen}
         isMobile={isMobile}
       />
    ));
   }

   if (buttons.length < 2) {
     if (appStyles.isUsingChargeCard)  {
       buttons.push((
         <RechargeButton
           appStyles={appStyles}
           T={T}
           navigateToTargetOrLoginMobile={navigateToTargetOrLoginMobile}
         />
       ))
     } else {
       buttons.push((
         <ScanButton
           appStyles={appStyles}
           T={T}
           navigateToTargetOrLoginMobile={navigateToTargetOrLoginMobile}
         />
       ))
     }
   }


  return (
    <div>
      <ButtonGroup
        appStyles={appStyles}
        tiles={false}
        classNames={classnames(
          styles.HideOnDesktop,
          // styles.ButtonGroupRow,
          styles.ButtonGroupRowForMinimalist,
          // !appStyles.homepageTileButtons && styles.ButtonGroupRow,
          appStyles.rtl && styles.RTL,
          roundedHomeButtons && styles.RoundedMobile,
        )}
        style={HomeButtonsMobile}
      >
        {buttons}
      </ButtonGroup>
    </div>
  );
}

export default function HomeButtons({
  T,
  appStyles,
  handleStartNewOrderClicked,
  isMobile,
  amountOfGiftsUnseen,
  disableRewards,
  navigateToTargetOrLoginMobile,
  navigateToTargetOrLoginDesktop,
  showMobilePromotions,
  isSSR,
}) {
  const {
    HomeButtonsDesktop = {},
    HomeButtonsMobile = {},
    roundedHomeButtons,
  } = appStyles;
  const disableReorder = _.includes(appStyles.disabledFeatures, "reorder");
  const disableOrder = _.includes(appStyles.disabledFeatures, "order");
  const tilesMode = isMobile && appStyles.homepageTileButtons;
  const StartNewOrderIcon = appStyles.isPizza
    ? NewOrderPizzaIcon
    : appStyles.isIceCream
      ? IceCreamIcon
      : NewOrderIcon;
  const mobileMinimalTemplateEnalbed = isTemplateEnabled(appStyles, HOMEPAGE_TEMPLATE_KEY.minimalist) && isMobile;
  if (mobileMinimalTemplateEnalbed) {
    return (
      <MinimalTemplateButtons
        appStyles={appStyles}
        T={T}
        StartNewOrderIcon={StartNewOrderIcon}
        handleStartNewOrderClicked={handleStartNewOrderClicked}
        navigateToTargetOrLoginDesktop={navigateToTargetOrLoginDesktop}
        amountOfGiftsUnseen={amountOfGiftsUnseen}
        isMobile={isMobile}
      />
    );

  } else if (showMobilePromotions) {
    return (
      <div>
        <ButtonGroup
          appStyles={appStyles}
          classNames={classnames(
            styles.HideOnDesktop,

            styles.ButtonGroupRow,

            appStyles.rtl && styles.RTL,
          )}
          style={HomeButtonsMobile}
        >
          <OrderButton
            appStyles={appStyles}
            T={T}
            StartNewOrderIcon={StartNewOrderIcon}
            handleStartNewOrderClicked={handleStartNewOrderClicked}
          />
        </ButtonGroup>
      </div>
    );
  }

  return (
    <div>
      <DesktopHomeButtons
        appStyles={appStyles}
        handleStartNewOrderClicked={handleStartNewOrderClicked}
        T={T}
        StartNewOrderIcon={StartNewOrderIcon}
        navigateToTargetOrLoginDesktop={navigateToTargetOrLoginDesktop}
        amountOfGiftsUnseen={amountOfGiftsUnseen}
        disableRewards={disableRewards}
      />
      <ButtonGroup
        appStyles={appStyles}
        tiles={tilesMode}
        classNames={classnames(
          styles.HideOnDesktop,
          !appStyles.homepageImageSplit
            ? styles.ButtonGroupTile
            : styles.ButtonGroupRow,
          !appStyles.homepageTileButtons && styles.ButtonGroupRow,
          appStyles.rtl && styles.RTL,
          roundedHomeButtons && styles.RoundedMobile,
        )}
        style={HomeButtonsMobile}
      >
        {!disableOrder && (
          <OrderButton
            appStyles={appStyles}
            T={T}
            StartNewOrderIcon={StartNewOrderIcon}
            handleStartNewOrderClicked={handleStartNewOrderClicked}
          />
        )}
        {!disableReorder && (
          <ReorderButton
            appStyles={appStyles}
            T={T}
            navigateToTargetOrLoginDesktop={navigateToTargetOrLoginDesktop}
          />
        )}
        {disableOrder && disableReorder ? (
          appStyles.isUsingChargeCard ? (
            <RechargeButton
              appStyles={appStyles}
              T={T}
              navigateToTargetOrLoginMobile={navigateToTargetOrLoginMobile}
              iconStyles={{width: 44, height: 50}}
            />
          ) : (
            <ScanButton
              appStyles={appStyles}
              T={T}
              navigateToTargetOrLoginMobile={navigateToTargetOrLoginMobile}
            />
          )
        ) : null}

        {!disableRewards &&
          (appStyles.rewardsAlternative ? (
            <RewardsAlternativeButton
              appStyles={appStyles}
              T={T}
              to={appStyles.rewardsAlternative.href}
            />
          ) : (
            <RewardsButton
              appStyles={appStyles}
              T={T}
              onClick={
                navigateToTargetOrLoginMobile({
                  type: LOGIN_TYPES.GIFTS,
                  target: "/gifts",
                })
              }
              amountOfGiftsUnseen={amountOfGiftsUnseen}
              isMobile={isMobile}
            />
          ))}
        {isMobile &&
          appStyles.homepageTileButtons &&
          (appStyles.isUsingChargeCard ? (
            <RechargeButton
              appStyles={appStyles}
              T={T}
              navigateToTargetOrLoginMobile={navigateToTargetOrLoginMobile}
            />
          ) : appStyles.scanAlternative ? (
            <ScanAlternativeButton
              appStyles={appStyles}
              T={T}
              to={appStyles.scanAlternative.href}
            />
          ) : (
            <ScanButton
              navigateToTargetOrLoginMobile={navigateToTargetOrLoginMobile}
              appStyles={appStyles}
              T={T}
            />
          ))}
      </ButtonGroup>
    </div>
  );
}


