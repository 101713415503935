import React from "react";
import { connect } from "react-redux";
import withTranslation from "../hocs/withTranslation";
import { startNewOrder } from "../store/order/actions";
import { closeNewOrPrevOrderModal, openAuthView, openNewOrPrevOrderModal } from "../store/app/actions";
import { getPromotions, getStyles, getWidgets } from "../store/selectors";
import IndexView from "../components/IndexView";

const IndexPage = (props) => <IndexView {...props} />;

const mapStateToProps = (state, props) => {
  const { user, header, order, app } = state;

  return {
    appStyles: getStyles(state, props),
    promotions: getPromotions(state, props),
    widgets: getWidgets(state, props),
    user,
    order,
    app,
    skipPath: header.onCloseTarget,
  };
};

const mapDispatchToProps = (dispatch) => ({
  startNewOrder: () => dispatch(startNewOrder()),
  openAuthView: (loginType) => dispatch(openAuthView(loginType)),
  openNewOrPrevOrderModal: () => dispatch(openNewOrPrevOrderModal()),
  closeNewOrPrevOrderModal: () => dispatch(closeNewOrPrevOrderModal())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation(IndexPage));
